import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2029bad6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "areaSelect" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_select, {
      value: _ctx.area.country,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.area.country) = $event)),
      class: "countries",
      onChange: _ctx.countriesChange,
      placeholder: "国家"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countriesList, (item) => {
          return (_openBlock(), _createBlock(_component_a_select_option, {
            key: item.id,
            value: item.id
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.name), 1)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value", "onChange"]),
    _createVNode(_component_a_select, {
      value: _ctx.area.province,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.area.province) = $event)),
      class: "provinces",
      onChange: _ctx.provincesChange,
      placeholder: "省份"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.provincesList, (item) => {
          return (_openBlock(), _createBlock(_component_a_select_option, {
            key: item.id,
            value: item.id
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.name), 1)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value", "onChange"]),
    _createVNode(_component_a_select, {
      placeholder: "城市",
      value: _ctx.area.city,
      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.area.city) = $event)),
      class: "city",
      onChange: _ctx.cityChange
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cityList, (item) => {
          return (_openBlock(), _createBlock(_component_a_select_option, {
            key: item.id,
            value: item.id
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.name), 1)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value", "onChange"]),
    _createVNode(_component_a_select, {
      placeholder: "区域",
      value: _ctx.area.district,
      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.area.district) = $event)),
      class: "district",
      onChange: _ctx.districtChange
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.districtList, (item) => {
          return (_openBlock(), _createBlock(_component_a_select_option, {
            key: item.id,
            value: item.id
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.name), 1)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value", "onChange"])
  ]))
}