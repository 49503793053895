
import { defineComponent, reactive, toRefs, ref, provide } from 'vue'
import { Area, PeerClass } from '@/model/competitiveAnalysis/peerData/peerData'
import areaSelect from '@/components/areaSelect/index.vue'
import { useRoute, useRouter } from 'vue-router'
import accompanyApi from '@/axios/api/accompany'
import { message } from 'ant-design-vue'
import detailLayout from '@/components/detailLayout/index.vue'
// import Data from '@/model/common/Data'
export default defineComponent({
  name: 'addPeerData',
  components: {
    // comtentLayout,
    detailLayout,
    areaSelect,
  },
  setup() {
    //获取当前路由
    const route = useRoute()
    const router = useRouter()
    //获取表单组件
    const formRef = ref()
    //获取地区选择组件
    const areaSelect = ref()
    //页面数据
    const pageData = reactive({
      peerForm: new PeerClass() as PeerClass, //表单数据
      rules: {
        competitorName: [{ required: true, message: '同行名称不可为空', trigger: 'blur' }],
        companyCode: [{ required: true, message: '工商号不可为空', trigger: 'blur' }],
        shortName: [{ required: true, message: '同行简称不可为空', trigger: 'blur' }],
        isEnable: [{ required: true }],
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 28 },
      title: '新增同行',
      buttonName: '提交',
      area: {
        country: undefined,
        province: undefined,
        city: undefined,
        district: undefined,
      },
    })
    pageData.peerForm.isEnable = 1
    //重置功能
    const reset = () => {
      formRef.value.resetFields()
      areaSelect.value.reset({
        country: pageData.peerForm.country,
        province: pageData.peerForm.province,
        city: pageData.peerForm.city,
        district: pageData.peerForm.district,
      })
      getDetail()
    }
    //提交
    const submit = () => {
      console.log(pageData.peerForm)
      formRef.value
        .validate()
        .then(() => {
          accompanyApi[route.params.id ? 'AccompanyUpdate' : 'AccompanyAdd'](pageData.peerForm)
            .then(() => {
              message.success(route.params.id ? '修改成功' : '新增成功')
              router.push('/competitiveAnalysis/peerData')
            })
            .catch((err) => {
              message.error(err)
            })
        })
        .catch(() => {
          console.log('验证不通过')
        })
    }
    //获取地址
    const setArea = (area: Area) => {
      pageData.peerForm.country = area.country as string
      pageData.peerForm.province = area.province as string
      pageData.peerForm.city = area.city as string
      pageData.peerForm.district = area.district as string
      console.log(area)
    }
    const getDetail = () => {
      if (route.params.id) {
        accompanyApi
          .AccompanyGetInfo({ id: route.params.id })
          .then((res) => {
            pageData.peerForm = res
            pageData.title = pageData.peerForm.competitorName + '账号信息'
            // pageData.area.country = res.country
            // pageData.area.province = res.province
            // pageData.area.city = res.city
            // pageData.area.district = res.district
            Object.assign(pageData.area, res)
            areaSelect.value.initial()
          })
          .catch((err) => {
            message.error(err)
          })
      }
    }
    getDetail()
    provide('area', pageData.area)
    return {
      ...toRefs(pageData),
      formRef,
      reset,
      submit,
      areaSelect,
      setArea,
    }
  },
})
