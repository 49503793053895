import { $axios } from '../config/axios'
export interface Children {
  children: List[] | [] | Children
}
export interface List {
  value: string
  children?: List[] | [] | Children
}
export interface Area {
  id: string
  name: string
}
export interface AreaData {
  optionalType: string
  subParameter: string
}
export const $GetAreaList = (): Promise<List[]> => {
  return $axios.get('/mock/localList.json')
}
export const $GetArea = (data: AreaData): Promise<Area[]> => {
  return $axios.post('/api/optional/type/query', Object.assign(data, { noLoading: true }))
}
