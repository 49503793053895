
import { defineComponent, reactive, toRefs, computed } from 'vue'
import { Area } from '@/model/competitiveAnalysis/peerData/peerData'
import { $GetArea, Area as AreaList } from '@/axios/api/area'
import { message } from 'ant-design-vue'
export default defineComponent({
  name: 'areaSelect',
  props: {
    areaProp: {
      type: Object,
      default: () => {
        return {
          country: undefined,
          province: undefined,
          city: undefined,
          district: undefined,
        }
      },
    },
  },
  emits: ['setArea'],
  setup(props, { emit }) {
    const pageData = reactive({
      countriesList: [] as AreaList[],
      provincesList: [] as AreaList[],
      cityList: [] as AreaList[],
      districtList: [] as AreaList[],
    })
    //获取国家列表
    $GetArea({
      optionalType: 'country',
      subParameter: '',
    })
      .then((res) => {
        pageData.countriesList = res
      })
      .catch((err) => {
        message.error(err)
      })
    let area = computed(() => {
      return props.areaProp
    })
    const initial = async () => {
      pageData.provincesList = await $GetArea({
        optionalType: 'province',
        subParameter: area.value.country as string,
      })
      pageData.cityList = await $GetArea({
        optionalType: 'city',
        subParameter: area.value.province as string,
      })
      pageData.districtList = await $GetArea({
        optionalType: 'district',
        subParameter: area.value.city as string,
      })
    }
    const countriesChange = (val: string) => {
      $GetArea({
        optionalType: 'province',
        subParameter: val,
      })
        .then((res) => {
          pageData.provincesList = res
        })
        .catch((err) => {
          message.error(err)
        })
      pageData.cityList = []
      pageData.districtList = []
      area.value.province = undefined
      area.value.city = undefined
      area.value.district = undefined
      console.log(area)

      emit('setArea', area.value)
    }
    const provincesChange = (val: string) => {
      $GetArea({
        optionalType: 'city',
        subParameter: val,
      })
        .then((res) => {
          pageData.cityList = res
        })
        .catch((err) => {
          message.error(err)
        })
      pageData.districtList = []
      area.value.city = undefined
      area.value.district = undefined
      emit('setArea', area.value)
    }
    const cityChange = (val: string) => {
      $GetArea({
        optionalType: 'district',
        subParameter: val,
      })
        .then((res) => {
          pageData.districtList = res
        })
        .catch((err) => {
          message.error(err)
        })
      area.value.district = undefined
      emit('setArea', area.value)
    }
    const districtChange = () => {
      console.log(area)

      emit('setArea', area.value)
    }
    const reset = (obj: Area) => {
      Object.assign(area.value, obj)
    }
    return {
      ...toRefs(pageData),
      countriesChange,
      provincesChange,
      cityChange,
      districtChange,
      area,
      reset,
      initial,
    }
  },
})
