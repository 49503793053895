import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27a76312"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "from" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_areaSelect = _resolveComponent("areaSelect")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_detailLayout = _resolveComponent("detailLayout")!

  return (_openBlock(), _createBlock(_component_detailLayout, {
    isSubmitForm: true,
    onReset: _ctx.reset,
    onSubmit: _ctx.submit
  }, {
    detail: _withCtx(() => [
      _createVNode(_component_a_card, { title: _ctx.title }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_a_form, {
              ref: "formRef",
              model: _ctx.peerForm,
              rules: _ctx.rules,
              "label-col": _ctx.labelCol,
              layout: "vertical",
              "wrapper-col": _ctx.wrapperCol
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: "同行名称",
                  name: "competitorName"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _ctx.peerForm.competitorName,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.peerForm.competitorName) = $event)),
                      placeholder: "请输入同行名称"
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, {
                  label: "工商号",
                  name: "companyCode"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _ctx.peerForm.companyCode,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.peerForm.companyCode) = $event)),
                      placeholder: "请输入工商号"
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, {
                  label: "同行简称",
                  name: "shortName"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _ctx.peerForm.shortName,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.peerForm.shortName) = $event)),
                      placeholder: "请输入同行简称"
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, {
                  label: "首页地址",
                  name: "urlIndex"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _ctx.peerForm.urlIndex,
                      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.peerForm.urlIndex) = $event)),
                      placeholder: "请输入首页地址"
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, {
                  label: "所属区域",
                  name: "area",
                  autoLink: false
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_areaSelect, {
                      ref: "areaSelect",
                      onSetArea: _ctx.setArea,
                      areaProp: _ctx.area
                    }, null, 8, ["onSetArea", "areaProp"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, {
                  label: "开启状态",
                  name: "isEnable"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_switch, {
                      checked: _ctx.peerForm.isEnable,
                      "onUpdate:checked": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.peerForm.isEnable) = $event)),
                      "checked-children": "开",
                      checkedValue: 1,
                      unCheckedValue: 0
                    }, null, 8, ["checked"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model", "rules", "label-col", "wrapper-col"])
          ])
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    _: 1
  }, 8, ["onReset", "onSubmit"]))
}